import React, { useState, useRef, useEffect } from "react"
import classes from "./mobile_nav.module.scss"
import Burger from "./Burger/Burger"
import Menu from "./Menu/Menu"
import FocusLock from "react-focus-lock"

const MobileNav = () => {
  const [isOpened, setIsOpened] = useState(false)
  const node = useRef()
  useOnClickOutside(node, () => setIsOpened(false))
  const menuId = "main-menu"

  return (
    <div ref={node} className={classes.mobileNavigation}>
      <FocusLock disabled={!isOpened}>
        <div className={classes.flexContainer}>
          <span className={classes.menuSpan}>MENU</span>
          <Burger
            open={isOpened}
            setOpen={setIsOpened}
            aria-controls={menuId}
          />
        </div>
        <Menu open={isOpened} setOpen={setIsOpened} id={menuId} />
      </FocusLock>
    </div>
  )
}
const useOnClickOutside = (ref, handler) => {
  useEffect(() => {
    const listener = event => {
      if (!ref.current || ref.current.contains(event.target)) {
        return
      }
      handler(event)
    }
    document.addEventListener("mousedown", listener)

    return () => {
      document.removeEventListener("mousedown", listener)
    }
  }, [ref, handler])
}
export default MobileNav
