/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React from "react"
import { Link } from "gatsby"
import CookieConsent from "react-cookie-consent"

import Navbar from "./navbar/navbar"
import Footer from "./footer/footer"
import "./layout.css"

const Layout = ({ children }) => {
  return (
    <>
      <Navbar />
      <div className="navbar-margin" />
      <CookieConsent
        location="bottom"
        buttonText="Rozumiem"
        declineButtonText="Decline"
        cookieName="gatsby-gdpr-google-analytics"
        style={{
          fontSize: "3rem",
        }}
        buttonStyle={{
          color: "#2D3D45",
          backgroundColor: "#E1A590",
          fontSize: "2.8rem",
          borderRadius: "2px",
        }}
      >
        Używamy plików cookies, aby ułatwić Ci korzystanie z naszego serwisu
        oraz do celów statystycznych. Korzystając z naszej strony wyrażasz zgodę
        na wykorzystywanie przez nas plików cookies. Jeśli nie blokujesz tych
        plików, to zgadzasz się na ich użycie oraz zapisanie w pamięci
        urządzenia. Pamiętaj, że możesz samodzielnie zarządzać cookies,
        zmieniając ustawienia przeglądarki. W razie dalszych informacji przejdź
        do{" "}
        <Link
          to="/polityka-prywatnosci"
          style={{ fontSize: "3rem", color: "blue" }}
        >
          Polityki prywatności
        </Link>
        .
      </CookieConsent>
      <main className="page-container">{children}</main>
      <Footer />
    </>
  )
}


export default Layout
