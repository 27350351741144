import React from "react"
import classes from "./footer_item.module.scss"

const FooterItem = ({ title, description, logo }) => {
  return (
    <li className={classes.item} key={title}>
      <span className={classes.icon}>{logo}</span>
      <span className={classes.title}>{title}</span>
      <span className={classes.description}>{description}</span>
    </li>
  )
}

export default FooterItem
