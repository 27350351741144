import React from "react"
import { bool } from "prop-types"
import { StyledMenu } from "./Menu.styled"
import { Link } from "gatsby"
import classes from "../mobile_nav.module.scss"
import facebookIcon from "../../../../images/facebook_logo.svg"
import instagramIcon from "../../../../images/instagram_logo.svg"
import { INSTAGRAM_URL, FB_URL } from "../../../../const"


const Menu = ({ open, ...props }) => {
  const isHidden = open ? true : false
  const tabIndex = isHidden ? 0 : -1

  return (
    <StyledMenu open={open} aria-hidden={!isHidden} {...props}>
      <Link
        tabIndex={tabIndex}
        to="/o-mnie/"
        className={classes.listItem}
        activeClassName={classes.active}
      >
        o mnie
      </Link>
      <Link
        tabIndex={tabIndex}
        to="/kontakt/"
        className={classes.listItem}
        activeClassName={classes.active}
        partiallyActive
      >
        kontakt
      </Link>
      <Link
        tabIndex={tabIndex}
        to="/zabiegi/"
        className={classes.listItem}
        activeClassName={classes.active}
        partiallyActive
      >
        zabiegi
      </Link>
      <Link
        tabIndex={tabIndex}
        to="/cennik/"
        className={classes.listItem}
        activeClassName={classes.active}
        partiallyActive
      >
        cennik
      </Link>
      <Link
        tabIndex={tabIndex}
        to="/problem/"
        className={classes.listItem}
        activeClassName={classes.active}
        partiallyActive
      >
        problem
      </Link>
      <Link
        tabIndex={tabIndex}
        to="/pliki"
        className={classes.listItem}
        activeClassName={classes.active}
        partiallyActive
      >
        do pobrania
      </Link>
      <Link
        tabIndex={tabIndex}
        to="/blog"
        className={classes.listItem}
        activeClassName={classes.active}
        partiallyActive
      >
        blog
      </Link>
      <ul className={classes.socialIcons}>
        <li key="facebook_logo" className={classes.socialIcon}>
          <a
            href={FB_URL}
            rel="noreferrer"
            target="_blank"
          >
            <img
              src={facebookIcon}
              alt="Ikona facebooka"
              width="50"
              height="50"
            />
          </a>
        </li>
        <li key="instagram_logo" className={classes.socialIcon}>
          <a
            href={INSTAGRAM_URL}
            rel="noreferrer"
            target="_blank"
          >
            <img
              src={instagramIcon}
              alt="Ikona instagrama"
              width="50"
              height="50"
            />
          </a>
        </li>
      </ul>
    </StyledMenu>
  )
}

Menu.propTypes = {
  open: bool.isRequired,
}

export default Menu
