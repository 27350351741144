import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import EmailIcon from "@material-ui/icons/Email"
import PhoneIcon from "@material-ui/icons/Phone"
import RoomIcon from "@material-ui/icons/Room"

import classes from "./footer.module.scss"
import footerClasses from "./footer_item/footer_item.module.scss"
import FooterItem from "./footer_item/footer_item"
const Footer = () => {
  const data = useStaticQuery(graphql`
    query footerInfo {
      strapiKontakt {
        email
        adres_dodatkowe_info
        telefon
        ulica
        miasto
      }
    }
  `)
  const items = [
    {
      title: "Napisz jeżeli masz pytania:",
      description: data.strapiKontakt.email,
      logo: <EmailIcon />,
    },
    {
      title: "Zadzwoń:",
      description: data.strapiKontakt.telefon,
      logo: <PhoneIcon />,
    },
  ]
  const room = data.strapiKontakt.adres_dodatkowe_info.split(",")
  return (
    <footer id="kontakt">
      <ul className={classes.itemsList}>
        <ul>
          {items.map(item => (
            <FooterItem
              key={item.title}
              title={item.title}
              description={item.description}
              logo={item.logo}
            />
          ))}
        </ul>

        <li className={footerClasses.item}>
          <span className={footerClasses.icon}><RoomIcon /></span>
          <span className={footerClasses.title}>Adres</span>
          <div className={classes.address}>
            <p className={classes.addressLine}>{data.strapiKontakt.miasto}</p>
            <p className={classes.addressLine}>{data.strapiKontakt.ulica}</p>
            <p className={classes.addressLine}>{room[0].concat(" ").concat(room[1])}</p>
          </div>
        </li>
      </ul>
      <ul className={classes.itemsList}>
        <li className={classes.copyrightItem}>
          Copyright 2020 ©Harp0n. Wszelkie prawa zastrzeżone. <br/>
          Stworzone przy pomocy{" "}
          <span className={classes.harponInfo}>Strapi</span> oraz{" "}
          <span className={classes.harponInfo}>Gatsby</span>.
        </li>
        <li className={classes.copyrightItem}>
          Stworzone i zaprojektowane przez{" "}
          <span className={classes.harponInfo}>
            <a
              href="https://github.com/Harp0n"
              alt="Harpon"
              target="_blank"
              rel="noopener noreferrer"
              className={classes.harponLink}
            >
              Harp0n
            </a>
          </span>
        </li>
      </ul>
    </footer>
  )
}
export default Footer
