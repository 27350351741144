import React from "react"
import { Link } from "gatsby"

import classes from "./desktop_nav.module.scss"
import facebookIcon from "../../../images/facebook_logo.svg"
import instagramIcon from "../../../images/instagram_logo.svg"
import { INSTAGRAM_URL, FB_URL } from "../../../const"

const DesktopNav = () => {
  return (
    <ul className={classes.list}>
      <Link
        to="/o-mnie/"
        className={classes.listItem}
        activeClassName={classes.active}
      >
        o mnie
      </Link>
      <Link
        to="/zabiegi/"
        className={classes.listItem}
        activeClassName={classes.active}
        partiallyActive
      >
        zabiegi
      </Link>
      <Link
        to="/cennik/"
        className={classes.listItem}
        activeClassName={classes.active}
        partiallyActive
      >
        cennik
      </Link>
      <Link
        to="/problem/"
        className={classes.listItem}
        activeClassName={classes.active}
        partiallyActive
      >
        problem
      </Link>
      <Link
        to="/blog"
        className={classes.listItem}
        activeClassName={classes.active}
        partiallyActive
      >
        blog
      </Link>
      <Link
        to="/kontakt"
        className={classes.listItem}
        activeClassName={classes.active}
        partiallyActive
      >
        kontakt
      </Link>
      <Link
        to="/pliki"
        className={classes.listItem}
        activeClassName={classes.active}
        partiallyActive
      >
        do pobrania
      </Link>
      <ul className={classes.socialList}>
        <li key="navbar_logo" className={classes.socialIcon}>
          <a
            href={FB_URL}
            rel="noreferrer"
            target="_blank"
          >
            <img
              src={facebookIcon}
              alt="Ikona facebooka"
              width="60"
              height="60"
            />
          </a>
        </li>
        <li key="insta_logo" className={classes.socialIcon}>
          <a
            href={INSTAGRAM_URL}
            rel="noreferrer"
            target="_blank"
          >
            <img
              src={instagramIcon}
              alt="Ikona facebooka"
              width="60"
              height="60"
            />
          </a>
        </li>
      </ul>
    </ul>
  )
}

export default DesktopNav
