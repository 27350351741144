import React from "react"
import { Link } from "gatsby"
import classes from "./navbar.module.scss"
import logo from "../../images/slusarczyk_logo.png"
import DesktopNav from "./desktop/desktop_nav.js"
import MobileNav from "./mobile/mobile_nav"

const Navbar = () => {
  return (
    <>
      <nav className={classes.navbar}>
        <Link to="/" className={classes.mainLogo}>
          <img src={logo} alt="Moje logo" />
        </Link>
        <DesktopNav />
        <MobileNav />
      </nav>
    </>
  )
}
export default Navbar
