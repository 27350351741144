import styled from 'styled-components';

export const StyledMenu = styled.nav`
display: flex;
flex-direction: column;
justify-content: center;
background: white;
transform: ${({ open }) => open ? 'translateX(0)' : 'translateX(100%)'};
height: 100vh;
text-align: center;
padding: 2rem;
position: absolute;
top: 0;
left: 0;
transition: transform 0.3s ease-in-out;
width: 100%;
a {
    outline: none;
  font-size: 2.5rem;
  text-transform: uppercase;
  padding: 1rem 0;
  font-weight: bold;
  color: #2D3D45;
  text-decoration: none;
  transition: color 0.3s linear;

  @media (max-width: 576px) {
    font-size: 2.5rem;
    text-align: center;
  }

  &:hover {
    color: #2D3D45;
  }
}
`